var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"panel-content bg-white border"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mt-4"},[_c('div',{staticClass:"font-20 widget-title float-left ml-10"},[_vm._v(" List of Collections "),(_vm.designers)?_c('span',{staticClass:"tableTotal font-15 poppins"},[_vm._v("(Total : "+_vm._s(_vm.designers.length)+")")]):_vm._e()]),_c('div',{staticClass:"float-right d-flex mx-4"},[_c('div',{staticClass:"float-right"},[_c('router-link',{staticClass:"Create-btn btn btn-success btn-small",attrs:{"to":"/createCollection"}},[_vm._v("Create")])],1)])])]),_c('div',{staticClass:"row justify-center",attrs:{"align":"center"}},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"widget"},[_c('div',{staticClass:"table-area"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},[_vm._m(1),_c('tbody',[_vm._l((_vm.designers),function(item,index){return _c('tr',{key:item._id},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(index + 1 + (_vm.page - 1) * 20)+" ")]),_c('td',[(item.imageMobile)?_c('div',[_vm._v(_vm._s(item.imageMobile[0]))]):_vm._e()]),_c('td',[(item.image)?_c('div',[_vm._v(_vm._s(item.image[0]))]):_vm._e()]),_c('td',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-8"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"min-width":"40px","height":"40px","color":"green","to":{
                                  name: 'editCollection',
                                  params: { id: item._id },
                                },"append":""}},'v-btn',attrs,false),on),[_c('b-icon-pencil',{staticClass:"text-white"})],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])],1),_c('div',{staticClass:"mr-8"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"b-modal",rawName:"v-b-modal.delete-modal",modifiers:{"delete-modal":true}}],attrs:{"min-width":"40px","height":"40px","color":"red"},on:{"click":function($event){return _vm.deleteConfirm(item._id)}}},'v-btn',attrs,false),on),[_c('b-icon-trash',{attrs:{"color":"white"}})],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)])])])}),_c('div')],2)])]),(!_vm.designers)?_c('div',{staticClass:"table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"},[_vm._v(" No Data Found ")]):_vm._e()])])])])]),(_vm.designers)?_c('div',{staticClass:"text-center mt-4"},[_c('v-pagination',{staticClass:"mb-2 float-center",attrs:{"length":_vm.totalPages,"per-page":20,"prev-icon":"<","next-icon":">"},on:{"input":function($event){return _vm.getAlldesigners()}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e(),_c('ConfirmDlg',{ref:"confirm"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"heading-sec"},[_c('div',{staticClass:"col-md-4 mt-6"},[_c('div',{staticClass:"poppins-medium font-30 heading-profile ml-7"},[_vm._v("Collections")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"col-md-1 text-center"},[_vm._v("#")]),_c('th',{staticClass:"col-md-3"},[_vm._v("Mobile Image")]),_c('th',{staticClass:"col-md-3"},[_vm._v("Desktop Image")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("Actions")])])])}]

export { render, staticRenderFns }