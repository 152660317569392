<template>
  <div>
    <div class="heading-sec">
      <div class="col-md-4 mt-6">
        <div class="poppins-medium font-30 heading-profile ml-7">Collections</div>
      </div>
    </div>
    <div class="panel-content bg-white border">
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="font-20 widget-title float-left ml-10">
            List of Collections
            <span v-if="designers" class="tableTotal font-15 poppins"
              >(Total : {{ designers.length }})</span
            >
          </div>
          <div class="float-right d-flex mx-4">
            <div class="float-right">
              <router-link to="/createCollection" class="Create-btn btn btn-success btn-small"
                >Create</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-center" align="center">
        <div class="col-md-12">
          <div class="widget">
            <div class="table-area">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="col-md-1 text-center">#</th>

                      <!-- <th class="col-md-2">Name</th> -->

                      <th class="col-md-3">Mobile Image</th>
                      <th class="col-md-3">Desktop Image</th>

                      <!-- <th class="col-md-2">Link</th> -->

                      <th colspan="2">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in designers" v-bind:key="item._id">
                      <td class="text-center">
                        {{ index + 1 + (page - 1) * 20 }}
                      </td>

                      <td>
                        <div v-if="item.imageMobile">{{ item.imageMobile[0] }}</div>
                      </td>

                      <td>
                        <div v-if="item.image">{{ item.image[0] }}</div>
                      </td>

                      <td>
                        <div class="d-flex">
                          <div class="mr-8">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="green"
                                  :to="{
                                    name: 'editCollection',
                                    params: { id: item._id },
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                  append
                                >
                                  <b-icon-pencil class="text-white"></b-icon-pencil>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip>
                          </div>

                          <div class="mr-8">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="red"
                                  v-bind="attrs"
                                  v-on="on"
                                  v-b-modal.delete-modal
                                  @click="deleteConfirm(item._id)"
                                >
                                  <b-icon-trash color="white"></b-icon-trash>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <div></div>
                  </tbody>
                </table>
              </div>
              <div
                class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                v-if="!designers"
              >
                No Data Found
              </div>

              <!-- Use text in props -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-4" v-if="designers">
      <v-pagination
        class="mb-2 float-center"
        v-model="page"
        :length="totalPages"
        :per-page="20"
        prev-icon="<"
        next-icon=">"
        @input="getAlldesigners()"
      />
    </div>
    <ConfirmDlg ref="confirm" />
  </div>
</template>

<script>
import homeService from '../../services/homeService';
import ConfirmDlg from '../../components/ConfirmDlg.vue';

export default {
  components: { ConfirmDlg },
  data() {
    return {
      searchText: '',
      designers: [
        {
          name: 'User 1',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 2',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 3',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 4',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 5',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 6',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 7',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 9',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 10',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 11',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 12',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 13',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 1',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 2',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 3',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 4',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 5',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 6',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 7',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 9',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 10',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 11',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 12',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 13',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
      ],
      page: 1,

      perPage: 10,
      errors: [],
      totalPages: 1,
      dialog: false,
    };
  },
  created() {
    this.getAlldesigners();
  },
  methods: {
    async getAlldesigners() {
      const data = {};
      data.page = this.page;
      data.user = JSON.parse(localStorage.getItem('selectedVendor'));
      const result = await homeService.getAllDesigners(data);
      if (result.status == 200) {
        this.designers = result.data.data;
        this.totalPages = result.data.paginationData.totalPages;
      }
      console.log('result', this.designers);
    },
    async deleteDesignerById(id) {
      const result = await homeService.deleteDesigner(id);

      if (result.status === 200) {
        this.$store.commit('setSnackbar', {
          content: 'Deleted Successfully',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
      this.$router.go();
      console.log('result', this.designers);
    },
    async deleteConfirm(id) {
      if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
        this.deleteDesignerById(id);
        console.log('got confirmation');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  width: 140px;
  max-height: 60px;
  font-size: 11px;
}
.Create-btn {
  color: white !important;
}
</style>
